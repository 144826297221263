import { Diff } from './diff';


export class ServerError extends Error {
  status: number;
  message: string;
  body?: { [k: string]: string };

  constructor(status: number, body?: { [k: string]: string }) {
    super();
    this.status = status;
    this.body = body;
    this.message = (body && body.error) || 'Internal server error.';
  }
}


const bodyOrError = async <T>(resp: Response): Promise<T> => {
  let body;
  try {
    body = await resp.json();
  } catch (e) {
    body = {};
  }

  if (resp.status >= 400) {
    throw new ServerError(resp.status, body);
  }

  return body;
};


export const STANDARD_PROFILE_NAMES_TO_DISPLAY_NAMES: { [k: string]: string } = {
  'Admin': 'System Administrator',
  'ContractManager': 'Contract Manager',
  'MarketingProfile': 'Marketing User',
  'ReadOnly': 'Read Only',
  'SolutionManager': 'Solution Manager',
  'Standard': 'Standard User',
  'StandardAul': 'Standard Platform User'
};


export type OrgSession = {
  email: string,
  fullName: string,
  instanceUrl: string
  isSandbox: boolean,
  organizationId: string,
  organizationName: string,
  userEmail: string,
  userName: string
};


export interface SessionResponse {
  session: OrgSession
  csrf_token: string
};


export interface AuthUrlResponse {
  url: string
};


export type FilterItem = {
  id: string;
  label: string;
};


export interface DiffResponse {
  id: string
};


export enum DiffState {
  IDLE = 'IDLE',
  ADDED = 'ADDED',
  CHANGED = 'CHANGED',
  REMOVED = 'REMOVED'
};


export enum DiffProcessState {
  COMPARING = 'COMPARING',
  DONE = 'DONE',
  ERROR = 'ERROR',
  SESSION_TIMEOUT = 'SESSION_TIMEOUT',
  WAITING = 'WAITING'
};


export type ItemDiff = {
  state: DiffState;
  name: string;
  type: string;
  description: string;
};


export type ParentEntityDiff = ItemDiff & {
  apex_classes: { [apexClass: string]: ApexClassAccessDiff };
  objects: { [objectName: string]: ObjectPermissionDiff };
  visualforce_pages: { [page: string]: VisualforcePageAccessDiff };
  display_name: string;
  process_state?: string;
  license?: string;
  user_permissions?: { [permission: string]: boolean };
};


export type ProfileDiff = ItemDiff & {
};


export type PermissionSetDiff = ItemDiff & {
};


export type FieldPermissionsDiff = ItemDiff & {
};


export type ApexClassAccessDiff = ItemDiff & {
  enabled: boolean;
};


export type ObjectPermissionDiff = ItemDiff & {
  fields: { [fieldName: string]: FieldPermissionsDiff };
};


export type VisualforcePageAccessDiff = ItemDiff & {
  enabled: boolean;
};


export interface DiffQueryFilters {
  includeFls: boolean
  includeSystemPermissions: boolean
  permissionSets: Set<string>
  profiles: Set<string>
};


export type DiffStatusResponse = {
  [itemKey: string]: {
    diff: ParentEntityDiff,
    state: DiffProcessState
  }
};


export type DeployLogEntryChange = {
  type: string;
  name: string;
  description: string;
};


export type DeployLogEntryError = {
  message: string;
  statusCode: number;
};


export type DeployLogEntry = {
  unixTime: number;
  type: string;
  name: string;
  label: string;
  description: string;
  changes: DeployLogEntryChange[];

  success: boolean;
  created: boolean;
  errors: DeployLogEntryError[];
};


export type DeployLog = DeployLogEntry[];


export enum DeployProcessState {
  DONE = 'DONE',
  ERROR = 'ERROR',
  WAITING = 'WAITING'
};


export interface DeployResponse {
  id: string
};


export interface DeployStatusResponse {
  state: DeployProcessState
  log: DeployLog
};


export class Api {
  baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async fetchSession(sessionOrgKey: string, controller?: AbortController): Promise<SessionResponse> {
    const options: RequestInit = {};
    if (controller) {
      options.signal = controller.signal;
    }
    const req = fetch(`${this.baseUrl}/api/session/${escape(sessionOrgKey)}`, options);
    return bodyOrError(await req);
  }

  async logout(
    sessionOrgKey: string,
    csrfToken: string,
    controller?: AbortController
  ) {
    const options: RequestInit = {
      method: 'DELETE',
      headers: {
        'X-CSRFToken': csrfToken
      }
    };
    if (controller) {
      options.signal = controller.signal;
    }
    const req = fetch(`${this.baseUrl}/api/session/org/${escape(sessionOrgKey)}`, options);
    return bodyOrError(await req);
  }

  async diff(
    parentEntity: string,
    sourceOrgKey: string,
    targetOrgKey: string,
    filters: DiffQueryFilters,
    csrfToken: string,
    controller?: AbortController
  ): Promise<DiffResponse> {
    const options: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrfToken
      },
      body: JSON.stringify({
        filters: {
          includeFls: filters.includeFls,
          includeSystemPermissions: filters.includeSystemPermissions,
          permissionSets: Array.from(filters.permissionSets),
          profiles: Array.from(filters.profiles)
        }
      })
    };

    if (controller) {
      options.signal = controller.signal;
    }

    const url = `${this.baseUrl}/api/diff/${escape(parentEntity)}/${escape(sourceOrgKey)}/${escape(targetOrgKey)}`;
    const req = fetch(url, options);

    return bodyOrError(await req);
  }

  async fetchDiff(
    diffId: string,
    itemKeys: string[],
    controller?: AbortController
  ): Promise<DiffStatusResponse> {
    const options: RequestInit = {};
    if (controller) {
      options.signal = controller.signal;
    }

    let url = `${this.baseUrl}/api/diff-status/${diffId}`;
    if (itemKeys.length) {
      url += `/${itemKeys.map(encodeURIComponent).join(',')}`;
    }

    const req = fetch(url, options);
    return bodyOrError(await req);
  }

  async fetchProfiles(sessionOrgKey: string, controller?: AbortController): Promise<FilterItem[]> {
    const options: RequestInit = {};
    if (controller) {
      options.signal = controller.signal;
    }
    const req = fetch(`${this.baseUrl}/api/profiles/${escape(sessionOrgKey)}`, options);
    return bodyOrError(await req);
  }

  async fetchPermissionSets(sessionOrgKey: string, controller?: AbortController): Promise<FilterItem[]> {
    const options: RequestInit = {};
    if (controller) {
      options.signal = controller.signal;
    }
    const req = fetch(`${this.baseUrl}/api/permission-sets/${escape(sessionOrgKey)}`, options);
    return bodyOrError(await req);
  }

  async deploy(
    sessionOrgKey: string,
    changes: Diff,
    csrfToken: string,
    controller?: AbortController
  ): Promise<DeployResponse> {
    const options: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrfToken
      },
      body: JSON.stringify(changes)
    };

    if (controller) {
      options.signal = controller.signal;
    }

    const req = fetch(`${this.baseUrl}/api/deploy/${escape(sessionOrgKey)}`, options);

    return bodyOrError(await req);
  }

  async fetchDeploy(deployId: string, controller?: AbortController): Promise<DeployStatusResponse> {
    const options: RequestInit = {};
    if (controller) {
      options.signal = controller.signal;
    }

    const req = fetch(`${this.baseUrl}/api/deploy-status/${deployId}`, options);
    return bodyOrError(await req);
  }

  async fetchAuthUrl(
    orgType: string,
    sessionOrgKey: string,
    controller?: AbortController
  ): Promise<AuthUrlResponse> {
    const options: RequestInit = {};
    if (controller) {
      options.signal = controller.signal;
    }
    const url = `${this.baseUrl}/api/auth-url/${escape(orgType)}/${escape(sessionOrgKey)}`;
    const req = fetch(url, options);
    return bodyOrError(await req);
  }
};
