import classnames from 'classnames';
import { sortBy } from 'lodash';
import React from 'react';
import ReactGA from 'react-ga';

import { FilterItem, ServerError } from './api';
import { Checkbox, Icon } from './lightning';


type Props = {
  id: string;
  title?: string;
  expanded?: boolean;
  items?: FilterItem[];
  selectedItems: Set<string>;
  fetchItems?: (controller: AbortController) => Promise<FilterItem[]>;
  onSelectionChange: (items: Set<string>) => void;
  onUnhandledError?: (e: ServerError) => void;
};


type State = {
  expanded: boolean;
  loading: boolean;
  items: FilterItem[];
  allSelected: boolean;
};


export class ItemFilter extends React.Component<Props, State> {
  _isMounted: boolean;
  abortController: AbortController;

  constructor(props: Props) {
    super(props);

    this.state = {
      expanded: Boolean(props.expanded),
      loading: false,
      items: [],
      allSelected: false
    };

    this._isMounted = false;
    this.abortController = new AbortController();
  }

  async componentDidMount() {
    this._isMounted = true;

    if (this.props.fetchItems) {
      let items;

      this.setState({ loading: true });

      try {
        items = await this.props.fetchItems(this.abortController);
      } catch (e) {
        if (this.props.onUnhandledError) {
          this.props.onUnhandledError(e);
        } else {
          throw e;
        }
        return;
      } finally {
        if (this._isMounted) {
          this.setState({ loading: false });
        }
      }

      this.setState({ items });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.abortController.abort();
    this.abortController = new AbortController();
  }

  render() {
    const allId = `${this.props.id}-checkAll`;

    const expanded = this.state.expanded && !this.state.loading;

    const iconHref = 'lightning/icons/utility-sprite/svg/symbols.svg#' + (
      expanded ? 'down' : 'right'
    );

    const items = this.props.items || this.state.items;

    let title;
    if (this.props.title && items) {
      let status: string;

      if (this.state.loading) {
        status = 'Loading...';
      } else {
        status = `${this.props.selectedItems.size} of ${items.length} selected`;
      }

      title = (
        <h3 onClick={this.onToggleExpand.bind(this, !this.state.expanded)}>
          <Icon text={true} href={iconHref} />
          <span>{this.props.title} <span className="status">({status})</span></span>
        </h3>
      );
    }

    return (
      <form className="item-filter">
        {title}

        <div className={classnames([
          'checkboxes',
          expanded ? 'slds-show' : 'slds-hide'
        ])}>
          <Checkbox
            id={allId}
            label="Select All"
            checked={this.state.allSelected}
            onChange={this.onSelectAll.bind(this, !this.state.allSelected)} />
          <ol>
            {sortBy(items, ({ label }) => label.toLowerCase()).map(({ id, label }) => {
              const selected = this.props.selectedItems.has(id);
              const key = `${this.props.id}-${id}`;

              return (
                <li key={key}>
                  <Checkbox id={key} label={decodeURIComponent(label)} checked={selected}
                    onChange={this.onSelectionChange.bind(this, id, !selected)} />
                </li>
              );
            })}
          </ol>
        </div>
      </form>
    );
  }

  onSelectionChange(id: string, selected: boolean) {
    ReactGA.event({ category: 'Filters', action: `${selected ? 'S' : 'Uns'}elect`, label: id });

    const selectedItems = new Set(this.props.selectedItems);

    if (selected) {
      selectedItems.add(id);
    } else {
      selectedItems.delete(id);
    }

    this.props.onSelectionChange(selectedItems);
  }

  onSelectAll(select: boolean) {
    this.setState(state => {
      const items = this.props.items || state.items;

      ReactGA.event({ category: 'Filters', action: `${select ? 'S' : 'Uns'}elect All`, value: items.length });
      const selectedItems = select ? new Set(items.map(({ id }) => id)) : new Set();
      // TODO Use redux so we aren't syncing state like this.
      this.props.onSelectionChange(selectedItems);

      return {
        allSelected: select
      };
    });
  }

  onToggleExpand(expanded: boolean) {
    ReactGA.event({ category: 'Filters', action: expanded ? 'Expand' : 'Collapse' });
    this.setState({ expanded });
  }
}
