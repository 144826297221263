import React from 'react';
import ReactGA from 'react-ga';

import { Api, OrgSession, ServerError } from './api';
import { OrgAuthenticator } from './auth';
import { DiffDeployer } from './diff';


type Props = {
  localStorageSupported: boolean;
};


type State = {
  api: Api,
  sessions: {[k: string]: OrgSession | null};
  unhandledError: string;
  csrfToken: string;
};


export default class App extends React.Component<Props, State> {
  abortController: AbortController;

  constructor(props: Props) {
    super(props);

    this.state = {
      api: new Api(process.env.REACT_APP_API_URL || ''),
      sessions: {
        orgA: null,
        orgB: null
      },
      unhandledError: '',
      csrfToken: ''
    };

    this.abortController = new AbortController();
  }

  async componentDidMount() {
    let orgA = null,
        orgB = null,
        csrfToken = '';

    try {
      [
        { session: orgA, csrf_token: csrfToken },
        { session: orgB }
      ] = await Promise.all([
        this.state.api.fetchSession('orgA', this.abortController),
        this.state.api.fetchSession('orgB', this.abortController)
      ]);
    } catch (e) {
      this.onUnhandledError(e);
      return;
    }

    this.setState({
      sessions: { orgA, orgB },
      csrfToken
    });
  }

  componentWillUnmount() {
    this.abortController.abort();
    this.abortController = new AbortController();
  }

  render() {
    const { api, sessions: { orgA, orgB }, unhandledError, csrfToken } = this.state;

    let body;

    if (orgA && orgB) {
      body = (
        <DiffDeployer
          api={api}
          sourceOrgKey="orgA"
          targetOrgKey="orgB"
          autoSelectUiFilters
          csrfToken={csrfToken}
          localStorageSupported={this.props.localStorageSupported}
          onUnhandledError={this.onUnhandledError.bind(this)} />
      );
    } else {
      let iframeSrc;
      if (window.location.hostname === 'localhost') {
        iframeSrc = 'data:text/plain,Intro iframe placeholder.';
      } else {
        const currentDomainAndTld = window.location.hostname.split('.').slice(-2).join('.');
        const pagePath = 'iframes/profiles-and-permission-deployments';
        iframeSrc = `https://${currentDomainAndTld}/${pagePath}`;
      }
      body = (
        <iframe
          title="Introduction"
          src={iframeSrc}>
        </iframe>
      );
    }

    return (
      <div className="app">
        <div className={['error-overlay', unhandledError ? 'visible' : ''].join(' ')}>
          <p>{unhandledError}</p>
        </div>

        <header>
          <div className="logo-title">
            <a href="https://bluecanvas.io/?utm_source=ppr" className="logo">
              <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="bluecanvas" />
            </a>

            <h1>
              <span className="title slds-text-heading_large">
                Tools / <a href="/perms/">Profiles &amp; Permissions Deployer <sup>Beta</sup></a>
              </span>
            </h1>
          </div>

          <OrgAuthenticator org={orgA} id="orgA" label="Source Org" api={api}
            logoutOrg={this.logoutOrg.bind(this, 'orgA', csrfToken)} />

          <OrgAuthenticator org={orgB} id="orgB" label="Target Org" api={api}
            logoutOrg={this.logoutOrg.bind(this, 'orgB', csrfToken)} />
        </header>

        {body}

        <footer>
          <p>&copy; 2023 <a href="https://bluecanvas.io/terms?utm_source=ppr">Blue Canvas Labs Inc.</a></p>

          <ul>
            <li><a href="https://bluecanvas.io/terms?utm_source=ppr">Terms of Service</a></li>
            <li><a href="https://bluecanvas.io/privacy?utm_source=ppr">Privacy Policy</a></li>
            <li><a href="https://bluecanvas.io/company/disclosure?utm_source=ppr">Responsible Disclosure</a></li>
          </ul>
        </footer>
      </div>
    );
  }

  async logoutOrg(sessionOrgKey: string, csrfToken: string) {
    try {
      await this.state.api.logout(sessionOrgKey, csrfToken, this.abortController);
    } catch (e) {
      this.onUnhandledError(e);
      return;
    }

    this.setState(({ sessions }) => ({
      sessions: {
        ...sessions,
        [sessionOrgKey]: null
      }
    }));
  }

  onUnhandledError(e: ServerError) {
    if ((e instanceof DOMException) && e.name === 'AbortError') {
      return;
    }

    ReactGA.exception({ description: e.message });

    const csrfToken = e.body && e.body.csrf_token;
    if (csrfToken) {
      this.setState({ csrfToken });
    }

    this.setState({
      unhandledError: e.message
    });

    if (e.status === 401) {
      this.setState({ sessions: {} });
    }

    setTimeout(() => {
      this.setState({
        unhandledError: ''
      });
    }, 3000);
  }
}
