import React from 'react';
import ReactGA from 'react-ga';

import { Api, OrgSession } from './api';
import { Button } from './lightning';


type Props = {
  api: Api;
  id: string;
  label: string;
  org: OrgSession | null;
  logoutOrg: () => void;
};


export class OrgAuthenticator extends React.Component<Props, {}> {
  abortController: AbortController;

  constructor(props: Props) {
    super(props);
    this.abortController = new AbortController();
  }

  componentWillUnmount() {
    this.abortController.abort();
    this.abortController = new AbortController();
  }

  render() {
    const { id, label, org } = this.props;

    let body, logoutButton;
    let description;

    if (org) {
      description = (
        <p className="description">
          {org.userName}<br />
          <a href={org.instanceUrl} title="Open in Salesforce" target="_blank"
             rel="noopener noreferrer">
            {org.instanceUrl}
          </a>
        </p>
      );

      logoutButton = (
        <Button label="Sign Out" onClick={this.onLogout.bind(this)} />
      );
    } else {
      body = (
        <p className="auth-buttons">
          <Button
            label="Connect Production"
            onClick={this.onAuthClick.bind(this, 'production', id)} />
          <Button
            label="Connect Sandbox"
            onClick={this.onAuthClick.bind(this, 'sandbox', id)} />
        </p>
      );
    }

    return (
      <div className="org-selector">
        <div className="name">
          <h2 className="slds-text-heading_small">{label}</h2>
          {description}
          {logoutButton}
        </div>
        {body}
      </div>
    );
  }

  async onAuthClick(orgType: string, orgSessionKey: string) {
    ReactGA.event({ category: 'Auth', action: 'Connect', label: orgType });
    const { url } = await this.props.api.fetchAuthUrl(
      orgType,
      orgSessionKey,
      this.abortController
    );
    ReactGA.outboundLink({
      label: `Connect ${orgType}`
    }, () => {
      window.location.href = url;
    });
  }

  onLogout(e: React.MouseEvent) {
    ReactGA.event({ category: 'Auth', action: 'Logout' });
    e.preventDefault();
    this.props.logoutOrg();
  }
}
