import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// AbortController must be polyfilled after window.fetch is polyfilled.
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import './index.css';
import App from './app';


const localStorageSupported = (): boolean => {
  const k = '__test__', v = 'A';

  try {
    window.localStorage.setItem(k, v);
    const v2 = window.localStorage.getItem(k);
    window.localStorage.removeItem(k);
    return v === v2;
  } catch (e) {
    return false;
  }
};


ReactDOM.render(<App localStorageSupported={localStorageSupported()} />, document.getElementById('app'));

if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
} else {
  // Silence warnings during local development.
  ReactGA.initialize('ID', { testMode: true });
}

ReactGA.pageview(window.location.pathname + window.location.search);
