/**
 * Minimal lightning design system components. No ARIA, props added as needed,
 * etc.
 *
 * Replace with https://react.lightningdesignsystem.com/ when it gets type
 * definitions.
 */

import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';


type AlertProps = {
  children: JSX.Element | string;
  alertType: string;
};


export const Alert = (props: AlertProps) => {
  const iconHref = `lightning/icons/utility-sprite/svg/symbols.svg#${props.alertType}`;

  const classes = [
    'slds-notify',
    'slds-notify_alert',
    'slds-theme_alert-texture',
    `slds-theme_${props.alertType}`,
    'slds-m-bottom_medium'
  ];

  const innerClasses = [
    'slds-icon_container',
    `slds-icon-utility-${props.alertType}`,
    'slds-m-right_x-small'
  ];

  return (
    <div className={classnames(classes)}>
      <span className="slds-assistive-text">warning</span>
      <span className={classnames(innerClasses)}>
        <Icon href={iconHref} />
      </span>
      {props.children}
    </div>
  );
};


type ButtonProps = {
  disabled?: boolean;
  label: string;
  onClick: (e: React.MouseEvent) => void;
};


export const Button = ({ disabled, label, onClick }: ButtonProps) => {
  return (
    <button className="slds-button slds-button_brand" disabled={disabled} onClick={onClick}>
      {label}
    </button>
  );
};


type CheckboxProps = {
  id: string;
  checked: boolean;
  label: string
  disabled?: boolean;
  onChange: () => void;
};


export const Checkbox = ({ id, checked, disabled, onChange, label }: CheckboxProps) => {
  return (
    <div className="slds-form-element">
      <div className="slds-form-element__control">
        <div className="slds-checkbox">
          <input type="checkbox" id={id} checked={checked} disabled={disabled} onChange={onChange} />
          <label className="slds-checkbox__label" htmlFor={id}>
            <span className="slds-checkbox_faux"></span>
            <span className="slds-form-element__label">{label}</span>
          </label>
        </div>
      </div>
    </div>
  );
};


interface DropdownProps {
  label: string
  children: JSX.Element | string;
};


export const Dropdown = (props: DropdownProps) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const classes = [
    'slds-dropdown-trigger',
    'slds-dropdown-trigger_click'
  ];

  if (isOpen) {
    classes.push('slds-is-open');
  }

  useEffect(() => {
    // Close the dropdown when the user clicks outside of it.
    const onClick = (e: MouseEvent) => {
      if (e.target instanceof Node) {
        let el: Node | null = e.target;

        while (el && el !== ref.current) {
          el = el.parentNode;
        }

        if (!el) {
          setIsOpen(false);
        }
      }
    };

    window.addEventListener('click', onClick);

    return () => {
      window.removeEventListener('click', onClick);
    };
  }, [])

  return (
    <div className={classnames(classes)} ref={ref}>
      <IconButton
        label={props.label}
        iconHref={`lightning/icons/utility-sprite/svg/symbols.svg#down`}
        onClick={() => setIsOpen(!isOpen)} />
      <div className="slds-dropdown slds-dropdown_left slds-dropdown_small">
        {props.children}
      </div>
    </div>
  );
}

type IconProps = {
  href: string;
  text?: boolean;
  classes?: string[];
};


export const Icon = (props: IconProps) => {
  let classes = [
    'slds-icon',
    'slds-icon_x-small'
  ];

  if (props.text) {
    classes.push('slds-icon-text-default');
  }

  if (props.classes) {
    classes = classes.concat(props.classes);
  }

  return (
    <svg className={classnames(classes)}>
      <use xlinkHref={`${process.env.PUBLIC_URL}/${props.href}`}></use>
    </svg>
  );
};


type IconButtonProps = {
  disabled?: boolean;
  iconHref: string;
  onClick: () => void;
  label?: string;
};


export const IconButton = ({ disabled, iconHref, onClick, label }: IconButtonProps) => {
  const buttonClasses = ['slds-button'];
  const svgClasses = ['slds-button__icon'];

  if (label) {
    buttonClasses.push('slds-button_neutral');
    svgClasses.push('slds-button__icon_left');
  } else {
    buttonClasses.push(
      'slds-button_icon-x-small',
      'slds-button_icon-border-filled'
    );
    svgClasses.push('slds-icon_x-small');
  }

  return (
    <button className={classnames(buttonClasses)} disabled={disabled}
      onClick={() => onClick()}>
      <svg className={classnames(svgClasses)}>
        <use xlinkHref={
          `${process.env.PUBLIC_URL}/${iconHref}`
        }></use>
      </svg>
      {label}
    </button>
  );
}


export type RadioGroupOption = [string, string];


type RadioGroupProps = {
  value: string;
  inputName: string;
  options: RadioGroupOption[];
  onChange: (id: string) => void;
};


export const RadioGroup = (props: RadioGroupProps) => {
  return (
    <div className="slds-radio_button-group">
      {props.options.map(([id, label]) => {
        const checked = props.value === id;
        return (
          <span className="slds-button slds-radio_button" key={id}>
            <input name={props.inputName} type="radio" id={id} value={id} checked={checked}
              onChange={() => props.onChange(id)} />
            <label className="slds-radio_button__label" htmlFor={id}>
              <span className="slds-radio_faux">{label}</span>
            </label>
          </span>
        );
      })}
    </div>
  );
};
