import classnames from 'classnames';
import { cloneDeep, isEmpty, isUndefined, sortBy } from 'lodash';
import React from 'react';
import ReactGA from 'react-ga';

import {
  Api,
  DeployLog,
  DiffStatusResponse,
  ServerError,
  STANDARD_PROFILE_NAMES_TO_DISPLAY_NAMES,

  ApexClassAccessDiff,
  FieldPermissionsDiff,
  DeployStatusResponse,
  DiffQueryFilters,
  FilterItem,
  ItemDiff,
  ObjectPermissionDiff,
  ParentEntityDiff,
  VisualforcePageAccessDiff
} from './api';
import { ItemFilter } from './filters';
import {
  Button,
  Checkbox,
  Dropdown,
  IconButton,
  RadioGroupOption,
  RadioGroup
} from './lightning';


const TYPES_TO_DISPLAY_TYPES: { [typeName: string]: string } = {
  Profile: 'Profile',
  PermissionSet: 'Permission Set',
  ApexClassAccess: 'Apex Class',
  FieldPermissions: 'Field Permissions',
  ObjectPermissions: 'Object Permissions',
  VisualforcePageAccess: 'Visualforce Page',
  Deployment: 'Deployment'
};


export type Diff = {
  [entityType: string]: { [name: string]: ParentEntityDiff };
};


type DiffDeployerProps = {
  api: Api;
  sourceOrgKey: string;
  targetOrgKey: string;
  onUnhandledError: (e: ServerError) => void;
  csrfToken: string;
  localStorageSupported: boolean;
  autoSelectUiFilters?: boolean;
};


type FilterSetKey = 'apexClasses' | 'objects' | 'visualforcePages';


type DiffUiFilters = {
  [k in FilterSetKey]: Set<string>
};


type DiffDeployerState = {
  parentEntityType: string;

  loadingDeploy: boolean;
  loadingDiff: boolean;

  diffId?: string;
  diff: Diff | null;
  changeset: Diff;
  changeLog: DeployLog;

  filters: DiffQueryFilters;
  uiFilters: DiffUiFilters;
  uiFilterSelections: DiffUiFilters;
};


const CHANGE_LOG_LOCALSTORAGE_KEY = 'changeLog';

const POLL_INITIAL_INTERVAL = 0.5 * 1000;
const POLL_MAX_INTERVAL = 3 * 1000;


export class DiffDeployer extends React.Component<DiffDeployerProps, DiffDeployerState> {
  abortController: AbortController;
  pollDeployTimer?: number;
  pollDiffTimer?: number;

  constructor(props: DiffDeployerProps) {
    super(props);

    let changeLog = [];

    if (props.localStorageSupported) {
      const changeLogJson = window.localStorage.getItem(CHANGE_LOG_LOCALSTORAGE_KEY);
      if (changeLogJson) {
        changeLog = JSON.parse(changeLogJson);
      }
    }

    this.state = {
      parentEntityType: 'Profile',

      loadingDeploy: false,
      loadingDiff: false,

      diff: null,
      changeset: {
        Profile: {},
        PermissionSet: {}
      },
      changeLog,
      filters: {
        includeFls: true,
        includeSystemPermissions: true,
        permissionSets: new Set(),
        profiles: new Set(),
      },
      uiFilters: {
        apexClasses: new Set(),
        objects: new Set(),
        visualforcePages: new Set()
      },
      uiFilterSelections: {
        apexClasses: new Set(),
        objects: new Set(),
        visualforcePages: new Set()
      }
    };

    this.abortController = new AbortController();
  }

  readDiffId = () => {
    const diffId = window.location.hash.replace(/^#/, '');

    this.setState({ diffId });

    if (diffId) {
      if (!isUndefined(this.pollDiffTimer)) {
        window.clearTimeout(this.pollDiffTimer);
      }

      this.setState({
        loadingDiff: true,
        diff: null,
        uiFilters: {
          apexClasses: new Set(),
          objects: new Set(),
          visualforcePages: new Set()
        }
      });

      this.pollDiff(diffId, POLL_INITIAL_INTERVAL, POLL_MAX_INTERVAL, []);
    }
  }

  componentDidMount() {
    this.readDiffId();

    window.addEventListener('hashchange', this.readDiffId);
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.readDiffId);

    if (!isUndefined(this.pollDeployTimer)) {
      window.clearTimeout(this.pollDeployTimer);
    }

    if (!isUndefined(this.pollDiffTimer)) {
      window.clearTimeout(this.pollDiffTimer);
    }

    this.abortController.abort();
    this.abortController = new AbortController();
  }

  onFilterChange(key: string, items: Set<string>) {
    this.setState(({ filters }) => ({
      filters: {
        ...filters,
        [key]: items
      }
    }));
  }

  onUiFilterChange(key: FilterSetKey, items: Set<string>) {
    this.setState(state => {
      return {
        uiFilterSelections: {
          ...state.uiFilterSelections,
          [key]: items
        }
      };
    });
  }

  render() {
    const loading = this.state.loadingDiff;

    const parentEntityTypes: RadioGroupOption[] = [
      ['Profile', TYPES_TO_DISPLAY_TYPES.Profile + 's'],
      ['PermissionSet', TYPES_TO_DISPLAY_TYPES.PermissionSet + 's']
    ];

    const filterGroupForType = ({
      Profile: this.state.filters.profiles,
      PermissionSet: this.state.filters.permissionSets
    } as { [type: string]: Set<string> })[this.state.parentEntityType];

    const filtersSet = filterGroupForType.size > 0;

    const compareDisabled = loading || this.state.loadingDeploy || !filtersSet;
    const addAllDisabled = loading || this.state.loadingDeploy;

    const hasChanges = Object.values(this.state.changeset)
      .some(changes => Boolean(Object.keys(changes).length));

    const filterGroups = [];

    if (this.state.parentEntityType === 'Profile') {
      filterGroups.push(
        <ItemFilter id="profiles" key="profiles" title="Profiles" expanded
          fetchItems={async (abortController) => {
            const profiles = await this.props.api.fetchProfiles(
              this.props.sourceOrgKey,
              abortController
            );

            return profiles.map(({ id, label }) => ({
              id,
              label: STANDARD_PROFILE_NAMES_TO_DISPLAY_NAMES[label] || label
            }));
          }}
          selectedItems={this.state.filters.profiles}
          onSelectionChange={this.onFilterChange.bind(this, 'profiles')}
          onUnhandledError={this.props.onUnhandledError} />
      );
    } else if (this.state.parentEntityType === 'PermissionSet') {
      filterGroups.push(
        <ItemFilter id="permissionSets" key="permissionSets" title="Permission Sets" expanded
          fetchItems={this.props.api.fetchPermissionSets.bind(this.props.api, this.props.sourceOrgKey)}
          selectedItems={this.state.filters.permissionSets}
          onSelectionChange={this.onFilterChange.bind(this, 'permissionSets')}
          onUnhandledError={this.props.onUnhandledError} />
      );
    }

    const apexClassFilters: FilterItem[] = [...this.state.uiFilters.apexClasses].sort()
      .map(apexClass => ({ id: apexClass, label: apexClass }));

    const objectFilters: FilterItem[]  = [...this.state.uiFilters.objects].sort()
      .map(sobject => ({ id: sobject, label: sobject }));

    const visualforcePageFilters: FilterItem[]  = [...this.state.uiFilters.visualforcePages].sort()
      .map(page => ({ id: page, label: page }));

    const selectedCountLabel = (label: string, filters: Set<any>, selected: Set<any>) => {
      return `${label} (${Math.min(selected.size, filters.size)}/${filters.size})`;
    };

    const apexClassDropdownLabel = selectedCountLabel(
      'Apex Classes',
      this.state.uiFilters.apexClasses,
      this.state.uiFilterSelections.apexClasses
    );

    const objectDropdownLabel = selectedCountLabel(
      'Objects',
      this.state.uiFilters.objects,
      this.state.uiFilterSelections.objects
    );

    const visualforcePageDropdownLabel = selectedCountLabel(
      'Visualforce Pages',
      this.state.uiFilters.visualforcePages,
      this.state.uiFilterSelections.visualforcePages
    );

    return (
      <div className="entity-comparison">
        <div className="filters">
          <div className="toolbar">
            <RadioGroup options={parentEntityTypes}
              value={this.state.parentEntityType}
              inputName="parentEntity"
              onChange={this.onParentEntityTypeChange.bind(this)} />
              {' '}
              <Button
                label="Compare"
                disabled={compareDisabled}
                onClick={this.onCompare.bind(this)} />
          </div>

          <div className="filter-controls">
            <ol className="extra-filters">
              <li>
                <Checkbox id="includeFls" label="Include Field-Level Security"
                  checked={this.state.filters.includeFls}
                  onChange={() => {
                    const selected = !this.state.filters.includeFls;

                    ReactGA.event({
                      category: 'Diff',
                      action: `${selected ? 'S' : 'Uns'}elect Field-Level Security`
                    });

                    this.setState(({ filters }) => ({
                      filters: {
                        ...filters,
                        includeFls: selected
                      }
                    }));
                  }} />
              </li>

              <li>
                <Checkbox id="includeSystemPermissions" label="Include System Permissions"
                  checked={this.state.filters.includeSystemPermissions}
                  onChange={() => {
                    const selected = !this.state.filters.includeSystemPermissions;

                    ReactGA.event({
                      category: 'Diff',
                      action: `${selected ? 'S' : 'Uns'}elect System Permissions`
                    });

                    this.setState(({ filters }) => ({
                      filters: {
                        ...filters,
                        includeSystemPermissions: selected
                      }
                    }));
                  }} />
              </li>
            </ol>

            {filterGroups}
          </div>
        </div>

        <div className="differences">
          <div className="toolbar">
            <div className="ui-filters">
              <Dropdown label={apexClassDropdownLabel}>
                <ItemFilter id="apexClasses" key="apexClasses" items={apexClassFilters}
                  expanded={true}
                  selectedItems={this.state.uiFilterSelections.apexClasses}
                  onSelectionChange={this.onUiFilterChange.bind(this, 'apexClasses')} />
              </Dropdown>
              {' '}
              <Dropdown label={objectDropdownLabel}>
                <ItemFilter id="objects" key="objects" items={objectFilters}
                  expanded={true}
                  selectedItems={this.state.uiFilterSelections.objects}
                  onSelectionChange={this.onUiFilterChange.bind(this, 'objects')} />
              </Dropdown>
              {' '}
              <Dropdown label={visualforcePageDropdownLabel}>
                <ItemFilter id="visualforcePages" key="objects" items={visualforcePageFilters}
                  expanded={true}
                  selectedItems={this.state.uiFilterSelections.visualforcePages}
                  onSelectionChange={this.onUiFilterChange.bind(this, 'visualforcePages')} />
              </Dropdown>
            </div>

            <p className="buttons">
              <Button
                label="Add All to Change Set"
                disabled={addAllDisabled}
                onClick={this.onAddAll.bind(this)} />
            </p>
          </div>

          <DiffList
            diff={this.state.diff}
            filterSelections={this.state.uiFilterSelections}
            showDescription={true}
            showState={true}
            noChangesView={
              <p className="slds-box">
                No changes to {TYPES_TO_DISPLAY_TYPES[this.state.parentEntityType]}s
                or their permissions for the selected filters.
              </p>
            }
            actionButtons={[{
              iconHref: 'lightning/icons/utility-sprite/svg/symbols.svg#add',
              onClick: this.onChangesetAdd.bind(this),
              title: 'Add to Change Set'
            }]} />
        </div>

        <div className="changeset">
          <div className="header">
            <h2 className="slds-text-heading_medium">Change Set</h2>

            <p className="buttons">
              <Button
                label="Clear"
                disabled={loading || this.state.loadingDeploy || !hasChanges}
                onClick={this.onClear.bind(this)} />
              {' '}
              <Button
                label={this.state.loadingDeploy ? 'Deploying' : 'Deploy'}
                disabled={this.state.loadingDeploy || !hasChanges}
                onClick={this.onDeploy.bind(this)} />
            </p>
          </div>

          <DiffList
            diff={this.state.changeset}
            showDescription={true}
            noChangesView={
              <p>
                No changes staged.
              </p>
            }
            actionButtons={[{
              iconHref: 'lightning/icons/utility-sprite/svg/symbols.svg#close',
              onClick: this.onChangesetRemove.bind(this),
              title: 'Remove from Change Set'
            }]} />

          <ChangeLog changes={[...this.state.changeLog].reverse()} />
        </div>
      </div>
    );
  }

  onParentEntityTypeChange(typeName: string) {
    ReactGA.event({ category: 'Diff', action: 'Select Entity', label: typeName });
    this.setState({
      diff: null,
      parentEntityType: typeName
    });
  }

  pollDiff(diffId: string, timeout: number, maxTimeout: number, itemKeys: string[]) {
    this.pollDiffTimer = window.setTimeout(async () => {
      let status: DiffStatusResponse;

      try {
        status = await this.props.api.fetchDiff(diffId, itemKeys, this.abortController);
      } catch (e) {
        this.setState({
          loadingDiff: false
        });
        this.props.onUnhandledError(e);

        // Redirect to empty diff on 403. The service returns 403 for missing
        // diffs also.
        if (e.status === 403) {
          window.location.hash = '';
        }

        return;
      }

      const incomplete = Object.keys(status).filter(k => {
        return status[k].state === 'WAITING' || status[k].state === 'COMPARING';
      });

      this.setState(({ diff, uiFilters, uiFilterSelections }) => {
        Object.values(status).forEach(({ state, diff: itemDiff }) => {
          if (!diff) {
            diff = {};
          }

          if (!diff[itemDiff.type]) {
            diff[itemDiff.type] = {};
          }

          itemDiff.process_state = state;
          diff[itemDiff.type][itemDiff.name] = itemDiff;

          // Add new items from the diffed entity to the keyed filter group.
          const updateFilters = (items: { [k: string]: ItemDiff }, filterKey: FilterSetKey) => {
            for (let v of Object.values(items)) {
              const isNew = !uiFilters[filterKey].has(v.name);
              uiFilters[filterKey].add(v.name);
              if (this.props.autoSelectUiFilters && isNew) {
                uiFilterSelections[filterKey].add(v.name);
              }
            }
          };

          updateFilters(itemDiff.apex_classes, 'apexClasses');
          updateFilters(itemDiff.objects, 'objects');
          updateFilters(itemDiff.visualforce_pages, 'visualforcePages');
        });

        return {
          diff: { ...diff },
          uiFilters: {
            apexClasses: new Set(uiFilters.apexClasses),
            objects: new Set(uiFilters.objects),
            visualforcePages: new Set(uiFilters.visualforcePages)
          },
          uiFilterSelections: {
            apexClasses: new Set(uiFilterSelections.apexClasses),
            objects: new Set(uiFilterSelections.objects),
            visualforcePages: new Set(uiFilterSelections.visualforcePages)
          }
        };
      });

      if (incomplete.length) {
        this.pollDiff(diffId, Math.min(maxTimeout, timeout), maxTimeout, incomplete);
      } else {
        this.setState({ loadingDiff: false });
      }
    }, timeout);
  }

  async onCompare() {
    ReactGA.event({ category: 'Diff', action: 'Compare' });

    this.setState({
      loadingDiff: true,
      diff: null
    });

    let diffRequest;
    try {
      diffRequest = await this.props.api.diff(
        this.state.parentEntityType,
        this.props.sourceOrgKey,
        this.props.targetOrgKey,
        this.state.filters,
        this.props.csrfToken,
        this.abortController
      );
    } catch (e) {
      ReactGA.event({ category: 'Diff', action: 'Compare Failed' });

      this.setState({
        loadingDiff: false,
        diff: null
      });

      this.props.onUnhandledError(e);
      return;
    }

    window.location.hash = diffRequest.id;
  }

  onAddAll() {
    if (!this.state.diff) {
      return;
    }

    ReactGA.event({ category: 'Diff', action: 'Add All', value: Object.entries(this.state.diff).length });

    this.setState(({ changeset }) => {
      [...Object.entries(this.state.diff as Diff)].forEach(([entityType, entityGroup]) => {
        Object.values(entityGroup).forEach(entity => {
          const groups = [
            entity.apex_classes,
            entity.objects,
            entity.visualforce_pages
          ];

          addItem(changeset, entity, [], this.state.uiFilterSelections);

          groups.forEach(group => {
            Object.values(group).forEach(item => {
              addItem(changeset, item, [entity], this.state.uiFilterSelections);
            });
          });

          Object.values(entity.objects).forEach(object => {
            Object.values(object.fields).forEach(field => {
              addItem(changeset, field, [object, entity], this.state.uiFilterSelections);
            });
          });
        });
      });

      return {
        changeset: {...changeset }
      };
    });
  }

  onClear() {
    ReactGA.event({ category: 'Diff', action: 'Clear' });
    this.setState({
      changeset: {
        PermissionSet: {},
        Profile: {}
      }
    });
  }

  pollDeploy(deployId: string, timeout: number, maxTimeout: number) {
    this.pollDeployTimer = window.setTimeout(async () => {
      let status: DeployStatusResponse;

      try {
        status = await this.props.api.fetchDeploy(deployId, this.abortController);
      } catch (e) {
        this.setState({ loadingDeploy: false });
        this.props.onUnhandledError(e);
        return;
      }

      if (status.state === 'DONE') {
        this.setState({ loadingDeploy: false });
        this.removeSuccessesFromChangeset(status.log);
        this.appendLog(status.log);
      } else if (status.state === 'ERROR') {
        this.props.onUnhandledError(new ServerError(200));
        this.setState({ loadingDeploy: false });
      } else {
        // Backoff.
        this.pollDeploy(deployId, Math.min(maxTimeout, timeout * 1.25), maxTimeout);
      }
    }, timeout);
  }

  removeSuccessesFromChangeset(changes: DeployLog) {
    const successes: { [typeName: string]: Set<string> } = {
      Profile: new Set<string>(),
      PermissionSet: new Set<string>()
    };

    changes.forEach(({ type, name, success }) => {
      if (success) {
        successes[type].add(name);
      }
    });


    this.setState(({ changeset }) => {
      [...Object.entries(successes)].forEach(([entityType, entitySuccesses]) => {
        entitySuccesses.forEach(name => {
          delete changeset[entityType][name];
        });
      });

      return { changeset };
    });
  }

  appendLog(changes: DeployLog) {
    const changeLog = this.state.changeLog.concat(changes);

    if (this.props.localStorageSupported) {
      window.localStorage.setItem(CHANGE_LOG_LOCALSTORAGE_KEY, JSON.stringify(changeLog));
    }

    this.setState({ changeLog });
  }

  async onDeploy() {
    if (!window.confirm(confirmationMessage(this.state.changeset))) {
      ReactGA.event({ category: 'Diff', action: 'Deny Deploy' });
      return;
    }

    ReactGA.event({ category: 'Diff', action: 'Deploy' });
    this.setState({
      loadingDeploy: true
    });

    let deployRequest;
    try {
      deployRequest = await this.props.api.deploy(
        this.props.targetOrgKey,
        this.state.changeset,
        this.props.csrfToken,
        this.abortController
      );
      ReactGA.event({ category: 'Diff', action: 'Deploy Started' });
    } catch (e) {
      ReactGA.event({ category: 'Diff', action: 'Deploy Error' });
      this.props.onUnhandledError(e);
      return;
    }

    this.pollDeploy(deployRequest.id, POLL_INITIAL_INTERVAL, POLL_MAX_INTERVAL);
  }

  onChangesetAdd(item: ItemDiff, parents: ItemDiff[]) {
    ReactGA.event({ category: 'Diff', action: 'Add', label: item.type });
    this.setState(({ changeset }) => {
      addItem(changeset, item, parents, this.state.uiFilterSelections);

      return {
        changeset: { ...changeset }
      };
    });
  }

  onChangesetRemove(item: ItemDiff, parents: ItemDiff[]) {
    ReactGA.event({ category: 'Diff', action: 'Remove', label: item.type });
    this.setState(({ changeset }) => ({
      changeset: { ...removeItem(changeset, item, parents) }
    }));
  }
}


type ActionButtonSpec = {
  iconHref: string;
  onClick: (item: ItemDiff, parents: ItemDiff[]) => void;
  title: string;
};


type DiffListProps = {
  diff: Diff | null;
  filterSelections?: DiffUiFilters;
  showDescription?: boolean;
  showState?: boolean;
  actionButtons: ActionButtonSpec[];
  noChangesView: React.ReactElement | null;
  notLoadedView?: React.ReactElement | null;
};


const DiffList = (props: DiffListProps) => {
  if (!props.diff) {
    return props.notLoadedView || null;
  }

  const hasChanges = Object.values(props.diff)
    .some(changes => Boolean(Object.keys(changes).length));

  if (!hasChanges) {
    return props.noChangesView;
  }

  const { filterSelections, showDescription } = props;

  const displayNameKey = ({ name, display_name, type }: ParentEntityDiff) => {
    let displayName: string;

    if (type === 'Profile') {
      displayName = STANDARD_PROFILE_NAMES_TO_DISPLAY_NAMES[name] || display_name;
    } else {
      displayName = display_name;
    }

    return displayName.toLowerCase();
  };

  const nameKey = ({ name }: ItemDiff) => name.toLowerCase();

  return (
    <ol className="items">
      {[...Object.entries(props.diff)].map(([entityType, entityGroup]) => {
        return (
          <React.Fragment key={entityType}>
            {sortBy(Object.values(entityGroup), displayNameKey).map(entity => {
              let { display_name } = entity;
              const {
                type,
                name,
                state,
                process_state,
                description,

                apex_classes,
                objects,
                visualforce_pages
              } = entity;

              const displayType = TYPES_TO_DISPLAY_TYPES[type] || type;

              if (type === 'Profile') {
                display_name = STANDARD_PROFILE_NAMES_TO_DISPLAY_NAMES[display_name] || display_name;
              }

              let itemState;
              if (props.showState) {
                itemState = <DiffItemState item={entity} />;
              }

              const itemClasses = [
                `state-${state}`,
                `process-state-${process_state}`
              ];

              return (
                <li key={type + ':' + name} className={classnames(itemClasses)}>
                  <span>
                    <span className="actions">
                      {props.actionButtons.map(({ iconHref, onClick }) => {
                        return (
                          <IconButton key={iconHref} iconHref={iconHref}
                            onClick={onClick.bind(null, entity, [])} />
                        );
                      })}
                    </span>
                    {' '}
                    {displayType}: {decodeURIComponent(display_name)}
                    {' '}
                    <span className="description">
                      {description && showDescription && `(${description})`}
                    </span>
                    {itemState}
                  </span>

                  <ol className="apex-classes">
                    {sortBy(Object.values(apex_classes), nameKey).map(apexClass => {
                      const { type, name, state, description } = apexClass;
                      const displayType = TYPES_TO_DISPLAY_TYPES[type] || type;

                      if (filterSelections && !filterSelections.apexClasses.has(name)) {
                        return null;
                      }

                      return (
                        <li key={name} className={'state-' + state}>
                          <span>
                            <span className="actions">
                              {props.actionButtons.map(({ iconHref, onClick }) => {
                                return (
                                  <IconButton key={iconHref} iconHref={iconHref}
                                    onClick={onClick.bind(null, apexClass, [entity])} />
                                );
                              })}
                            </span>
                            {' '}
                            {displayType}: {name}
                            {' '}
                            <span className="description">
                              {description && `(${description})`}
                            </span>
                          </span>
                        </li>
                      );
                    })}
                  </ol>

                  <ol className="objects">
                    {sortBy(Object.values(objects), nameKey).map(o => {
                      const { type, name, state, fields, description } = o;
                      const displayType = TYPES_TO_DISPLAY_TYPES[type] || type;

                      if (filterSelections && !filterSelections.objects.has(name)) {
                        return null;
                      }

                      return (
                        <li key={name} className={'state-' + state}>
                          <span>
                            <span className="actions">
                              {props.actionButtons.map(({ iconHref, onClick }) => {
                                return (
                                  <IconButton key={iconHref} iconHref={iconHref}
                                    onClick={onClick.bind(null, o, [entity])} />
                                );
                              })}
                            </span>
                            {' '}
                            {displayType}: {name}
                            {' '}
                            <span className="description">
                              {description && showDescription && `(${description})`}
                            </span>
                          </span>

                          <ol className="fields">
                            {sortBy(Object.values(fields), nameKey).map(field => {
                              const { type, name, state, description } = field;
                              const displayType = TYPES_TO_DISPLAY_TYPES[type] || type;

                              return (
                                <li key={name} className={'state-' + state}>
                                  <span>
                                    <span className="actions">
                                      {props.actionButtons.map(({ iconHref, onClick }) => {
                                        return (
                                          <IconButton key={iconHref} iconHref={iconHref}
                                            onClick={onClick.bind(null, field, [o, entity])} />
                                        );
                                      })}
                                    </span>
                                    {' '}
                                    {displayType}: {name}
                                    {' '}
                                    <span className="description">
                                      {description && showDescription && `(${description})`}
                                    </span>
                                  </span>
                                </li>
                              );
                            })}
                          </ol>
                        </li>
                      );
                    })}
                  </ol>

                  <ol className="visualforce-pages">
                    {sortBy(Object.values(visualforce_pages), nameKey).map(page => {
                      const { type, name, state, description } = page;
                      const displayType = TYPES_TO_DISPLAY_TYPES[type] || type;

                      if (filterSelections && !filterSelections.visualforcePages.has(name)) {
                        return null;
                      }

                      return (
                        <li key={name} className={'state-' + state}>
                          <span>
                            <span className="actions">
                              {props.actionButtons.map(({ iconHref, onClick }) => {
                                return (
                                  <IconButton key={iconHref} iconHref={iconHref}
                                    onClick={onClick.bind(null, page, [entity])} />
                                );
                              })}
                            </span>
                            {' '}
                            {displayType}: {name}
                            {' '}
                            <span className="description">
                              {description && `(${description})`}
                            </span>
                          </span>
                        </li>
                      );
                    })}
                  </ol>
                </li>
              );
            })}
          </React.Fragment>
        );
      })}
    </ol>
  );
};


const changesetItemEmpty = (item: ParentEntityDiff) => {
  return [
    item.apex_classes,
    item.objects,
    item.visualforce_pages
  ].every(isEmpty);
};


const getParent = (changeset: Diff, item: ParentEntityDiff) => {
  if (!changeset[item.type][item.name]) {
    changeset[item.type][item.name] = {
      ...item,
      apex_classes: {},
      objects: {},
      visualforce_pages: {}
    };
  }

  return changeset[item.type][item.name];
};


const addItem = (
  changeset: Diff,
  item: ItemDiff,
  parents: ItemDiff[],
  activeFilters: DiffUiFilters
) => {
  if (item.type === 'Profile' || item.type === 'PermissionSet') {
    const entity = item as ParentEntityDiff;

    if (item.state === 'IDLE' && changesetItemEmpty(entity)) {
      return;
    }

    if (!changeset[item.type][item.name]) {
      changeset[entity.type][entity.name] = {
        description: entity.description,
        display_name: entity.display_name,
        license: entity.license,
        user_permissions: { ...entity.user_permissions },
        name: entity.name,
        state: entity.state,
        type: entity.type,
        apex_classes: {},
        objects: {},
        visualforce_pages: {}
      };
    }

    for (let [k, v] of Object.entries(entity.apex_classes)) {
      if (activeFilters.apexClasses.has(k)) {
        addItem(changeset, v, [entity], activeFilters);
      }
    }

    for (let [k, v] of Object.entries(entity.objects)) {
      if (activeFilters.objects.has(k)) {
        addItem(changeset, v, [entity], activeFilters);
      }
    }

    for (let [k, v] of Object.entries(entity.visualforce_pages)) {
      if (activeFilters.visualforcePages.has(k)) {
        addItem(changeset, v, [entity], activeFilters);
      }
    }
  } else if (item.type === 'ObjectPermissions') {
    const [entity] = parents;

    const parentChange = getParent(changeset, entity as ParentEntityDiff);
    if (!parentChange) return false;

    const cloned = cloneDeep(item as ObjectPermissionDiff) as ObjectPermissionDiff;
    parentChange.objects[item.name] = cloned;

  } else if (item.type === 'FieldPermissions') {
    const [object, entity] = parents;

    const parentChange =  getParent(changeset, entity as ParentEntityDiff);
    if (!parentChange) return false;

    if (!parentChange.objects[object.name]) {
      parentChange.objects[object.name] = {
        ...(object as ObjectPermissionDiff),
        fields: {}
      };
    }

    const cloned = cloneDeep(item as FieldPermissionsDiff) as FieldPermissionsDiff;
    parentChange.objects[object.name].fields[item.name] = cloned;

  } else if (item.type === 'ApexClassAccess') {
    const [entity] = parents;

    const parentChange =  getParent(changeset, entity as ParentEntityDiff);
    if (!parentChange) return false;

    const cloned = cloneDeep(item as ApexClassAccessDiff) as ApexClassAccessDiff;
    parentChange.apex_classes[item.name] = cloned;

  } else if (item.type === 'VisualforcePageAccess') {
    const [entity] = parents;

    const parentChange =  getParent(changeset, entity as ParentEntityDiff);
    if (!parentChange) return false;

    const cloned = cloneDeep(item as VisualforcePageAccessDiff) as VisualforcePageAccessDiff;
    parentChange.visualforce_pages[item.name] = cloned;
  }
};


const removeItem = (changeset: Diff, item: ItemDiff, parents: ItemDiff[]): Diff => {
  let entity: ParentEntityDiff | undefined;
  let object: ObjectPermissionDiff | undefined;

  if (item.type === 'Profile' || item.type === 'PermissionSet') {
    delete changeset[item.type][item.name];

  } else if (item.type === 'ObjectPermissions') {
    [entity] = parents as [ParentEntityDiff];
    delete changeset[entity.type][entity.name].objects[item.name];

  } else if (item.type === 'FieldPermissions') {
    [object, entity] = parents as [ObjectPermissionDiff, ParentEntityDiff];

    const fieldObject = changeset[entity.type][entity.name].objects[object.name];
    delete fieldObject.fields[item.name];

    // Remove unchanged, empty object permissions.
    if (fieldObject.state === 'IDLE' && isEmpty(fieldObject.fields)) {
      delete changeset[entity.type][entity.name].objects[object.name];
    }
  } else if (item.type === 'ApexClassAccess') {
    [entity] = parents as [ParentEntityDiff];
    delete changeset[entity.type][entity.name].apex_classes[item.name];

  } else if (item.type === 'VisualforcePageAccess') {
    [entity] = parents as [ParentEntityDiff];
    delete changeset[entity.type][entity.name].visualforce_pages[item.name];
  }

  // Remove unchanged, empty parent object.
  if (entity && entity.state === 'IDLE' && changesetItemEmpty(changeset[entity.type][entity.name])) {
    delete changeset[entity.type][entity.name];
  }

  return changeset;
};


const ChangeLog = ({ changes }: { changes: DeployLog }) => {
  return (
    <div className="change-log">
      <h2 className="slds-text-heading_medium">Deploy Log</h2>

      {changes.length ? (
        <ol>
          {changes.map(change => {
            let action;
            let className;
            if (change.errors && change.errors.length) {
              className = 'ERROR';
              action = 'Failed';
            } else if (change.created) {
              className = 'CREATED';
              action = 'Created';
            } else if (change.type !== 'Deployment') {
              className = 'UPDATED';
              action = 'Updated';
            }

            const dt = new Date(change.unixTime * 1000);
            const time = dt.toISOString();

            let { description, label, name } = change;

            if (!label) {
              label = description;
            }

            if (!name) {
              name = description;
            }

            if (change.type === 'Profile') {
              label = STANDARD_PROFILE_NAMES_TO_DISPLAY_NAMES[name] || label;
            }

            return (
              <li key={change.type + ':' + change.unixTime + ':' + name}
                className={'change-' + className}>
                <time>{time}</time>
                {' '}
                {action}
                {' '}
                {TYPES_TO_DISPLAY_TYPES[change.type]}:
                {' '}
                {decodeURIComponent(label)}
                {' '}
                {description ? ': ' + description : ''}

                <ol className="change-items">
                  {change.changes && change.changes.map(changeItem => {
                    let description = changeItem.description;
                    if (description) {
                      description = ': ' + description;
                    }
                    return (
                      <li key={changeItem.type + ':' + changeItem.name}>
                        {TYPES_TO_DISPLAY_TYPES[changeItem.type]}:
                        {' '}
                        {changeItem.name}
                        {description}
                      </li>
                    );
                  })}
                  {change.errors && change.errors.length ? (
                    <ul className="errors">
                      {change.errors.map(error => (
                        <li key={error.message}>
                          {error.statusCode}:
                          {' '}
                          {error.message}
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </ol>
              </li>
            );
          })}
        </ol>
      ) : (
        <p>No changes.</p>
      )}
    </div>
  );
};


interface DiffItemStateProps {
  item: ParentEntityDiff
};


const DiffItemState = ({ item }: DiffItemStateProps) => {
  const statuses: [string, string][] = [];

  if (item.process_state === 'WAITING') {
    statuses.push(['waiting', 'Waiting...']);
  } else if (item.process_state === 'COMPARING') {
    statuses.push(['comparing', 'Comparing...']);
  } else if (item.process_state === 'ERROR') {
    statuses.push(['error', 'Error']);
  } else if (item.process_state === 'SESSION_TIMEOUT') {
    statuses.push(['error', 'Session expired']);
  } else if (item.process_state === 'DONE') {
    if (item.state === 'ADDED') {
      statuses.push(['new', 'Created']);
    } else {
      if (item.state === 'IDLE' && changesetItemEmpty(item)) {
        statuses.push(['unchanged', 'Unmodified']);
      } else {
        statuses.push(['changed', 'Modified']);
      }
    }
  }

  return (
    <div className="diff-item-state">
      {statuses.map(([classname, label]) => {
        const statusClasses = ['status', classname];
        return <span className={classnames(statusClasses)} key={`${classname}-${label}`}>{label}</span>;
      })}
    </div>
  );
};


type Counts = { [entityName: string]: number };


const changeCounts = (changeset: Diff) => {
  const nCreated: Counts = {
    Profile: 0,
    PermissionSet: 0
  };

  const nChanged: Counts = {
    Profile: 0,
    PermissionSet: 0
  };

  [...Object.entries(changeset)].forEach(([entityType, entityGroup]) => {
    Object.values(entityGroup).forEach(({ state }) => {
      if (state === 'ADDED') {
        nCreated[entityType]++;
      } else {
        nChanged[entityType]++;
      }
    });
  });

  return { nCreated, nChanged };
};


const confirmationMessage = (changeset: Diff): string => {
  const counts = changeCounts(changeset);

  const typeSummaries: string[] = [];

  ['Profile', 'PermissionSet'].forEach(type => {
    const descriptions: string[] = [];

    if (counts.nCreated[type]) {
      descriptions.push(counts.nCreated[type] + ' created');
    }

    if (counts.nChanged[type]) {
      descriptions.push(counts.nChanged[type] + ' updated');
    }

    if (descriptions.length) {
      typeSummaries.push(
        (TYPES_TO_DISPLAY_TYPES[type] || type) + ': ' + descriptions.join(', ') + '.'
      );
    }
  });

  return `Are you sure you want to deploy this changeset?

${typeSummaries.join('\n\n')}`;
};
